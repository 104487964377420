.seans_item {
    border-bottom: 3px solid red;
}

.seans_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border:1px solid rgb(146, 146, 146);
    background: white;
    padding: 5px;
    margin-bottom: 10px;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    &:hover {
        border:1px solid rgb(77, 75, 75);
       background: rgb(229 227 227);
    }
}



.seans_item_blocks {
    height: 600px;
    overflow-y: scroll;
    position: relative;
}

.btn_close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.information_block_top {
    display: flex;
    justify-content: center;
    margin-top: 0;
    position: relative;
}

.none {
    display: none;
}

.close_modal {
    background:none;
    border:none;
    width: 70px;
    height: 70px;
    position: absolute;
    top: -14px;
    right: 0;
}

.modal_seans_t12 {
    &.active {
        display: block;
    }
    div {
        padding: 0 !important;
    }
}

.information_block_inner {
    display: flex;
    justify-content: space-between;
    align-items:center;
    border: 1px solid black;
    padding: 5px;
    margin-bottom: 10px;

    .information_block_inner_right {
        display: flex;
        align-items: center;
        .information_text_right {
            margin-right: 15px;
            border:1px solid black;
            padding: 10px;
        }
    }
    h4 {
        margin: 0;
    }
}

.information_text_right {
    color: blue;
}

.information_block {
    display: flex;
    justify-content: space-between;
    align-items:center;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
    
    h4 {
        margin: 0;
        &:first-child {
            // width: 20%;
        }
        &:last-child {
            width: 60%;
            text-align: right;
        }
    }
}

.information_div {
    height: 600px;
    overflow-y: scroll;
}

.new_seans {
    display: flex;
    justify-content: space-between;
    align-items:center;
    button {
         border:none;
        background:none;
        padding: 5px 10px;
        background:#1976d2;
        border-radius: 6px;
        color: white;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
    }
}

.close_one_modal {
    background: none;
    border: none;
    display: flex;
    justify-content: flex-end;
}
.seans_item {

    button {
         border:none;
        background:none;
        padding: 5px 10px;
        background:#1976d2;
        border-radius: 6px;
        color: white;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
    }
    
}

.information_block_text {
    display: block;
    p {
        margin-top: 10px;
    }
}

.new_bemor_inner {
    padding-top: 15px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    div {
        width: 100%;
    }

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        width: 45% !important;
    }

    .css-1869usk-MuiFormControl-root {
        margin: 0 !important;
        width: 45% !important;
    }
}

.new_bemor {
    height: 600px;
    overflow-y: scroll;
    padding-bottom: 30px;
}


.textarea_9 {
    width: 100%;
    height: 200px !important;
    padding: 20px;
}

.new_bemor_inner_shikoyat {
    margin-bottom: 20px;
}

.new_bemor_inner_doctor {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    

    .css-1869usk-MuiFormControl-root {
        width: 45% !important;
    margin: 0 !important;

    }
}

.table_seans_block_inner {
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 10px;
    border-bottom: 1px solid black;
    div {
        width: 30%;
        h4 {
            text-align: center;

        }
    }
    h4 {
        margin: 0;
    }

    .name_item {
        h4 {
            text-align: left;
        }
    }
}



.name_item2 {
    display: flex;
    justify-content: flex-end;
}

.close_modal-1 {
    background: none;
    border: none;
}