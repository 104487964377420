.status{
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border-radius: 40px;
    &--1{
        border: 1px solid #E1E1E1;
        color: #888888;
        &::before{
            display: inline-block;
            margin-right: 10px;
            width: 16px;
            height: 16px;
            vertical-align: middle;
            background-image: url("../../../../../assets/icon/Yubor.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content: "";
        }
    }
    &--2{
        border: 1px solid #E1E1E1;
        color: #888888;
        &::before{
            display: inline-block;
            margin-right: 10px;
            width: 16px;
            height: 16px;
            vertical-align: middle;
            background-image: url("../../../../../assets/icon/time.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content: "";
        }
    }
    &--3{
        border: 1px solid #18CF6C;
        color: #18CF6C;
        &::before{
            display: inline-block;
            margin-right: 10px;
            width: 27px;
            height: 10px;
            vertical-align: middle;
            background-image: url("../../../../../assets/icon/ptichka.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content: "";
        }
    }
}