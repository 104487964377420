.table{
    .css-dsuxgy-MuiTableCell-root[data-type="4"]{
        border: 4px solid #E7EBF2 !important;
    }
}
.tabele__qoldiq{
    padding: 10px 30px 10px 15px !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    background-color: #fff;
    border-radius: 4px;
    &--Yashil{
        background-color: #1b510e;
        color: #fff !important; 
    }
    &--Qizil{
        background-color: #E63943;
        color: #fff !important;
    }
    &--Sariq{
        background-color: #F69641;
        color: #fff !important;
    }
}
.table__span{
    display: block; 
    margin-top: 12px;
    &::before{
        display: block; 
        margin-bottom: 12px;
        border-top: 1px solid #E1E1E1;
        content: "";
    }
}