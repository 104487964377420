.card-block{
    margin-top: 20px;
    padding: 28px;
    background-color: #fff;
    border-radius: 12px;
}
.card-block__head{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1464C0;
}
.card-date{
    &::before{
        display: block;
        margin-bottom: 4px;
        content: attr(data-content);
    }
    &--1{
        &::before{
            display: block;
            margin-bottom: 4px;
            content:  attr(data-content) !important;
        }
    }
    &--2{
        &::before{
            display: block;
            margin-bottom: 4px;
            content:attr(data-content) !important;
        }
    }
}
.card-uzs{
    margin-top: 16px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    color: #888888;
    &--green{
        border: 1px solid #A1F4C7;
        background-color:#a1f4c671;
        color:#18CF6C
    }
    &--red{
        border: 1px solid #c24c4ccf;
        background-color:#cf181852;
        color:#d22d2de4
    }
}
.card-text{
    width: 734px;
    padding-bottom: 156px;
    overflow-y: scroll;
    resize: none;
    outline: none;
    &::placeholder{
        margin-left: 16px;
    }
}
.card-page{
    padding-top: 300px;
    padding-bottom: 300px;
    margin: 0 auto;
    text-align:center;
    width:364px
}
.fix-file{
    padding: 34px 121px;
    margin-top: 16px;
    text-align:center;
}
.fix-btn{
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1464C0;
    background-color: #DDEBFB;
    border-radius: 12px;
    cursor: pointer;
    &::before{
        display: inline-block;
        width: 14px;
        height: 16px;
        margin-right: 7px;
        background-image: url("../../../../../../assets/icon/scripka.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
    }
}
.modal-two{
    background-color:#EFF2F5 !important;
    border-radius: 12px;
}