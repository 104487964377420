@import url('https://fonts.googleapis.com/css?family=Cabin+Sketch');

html {
	height: 100%;
}

body {
	min-height: 100%;
}

body {
	display: flex;
}

.site-head {
	font-family: 'Cabin Sketch', cursive;
	font-size: 3em;
	text-align: center;
	opacity: .8;
	order: 1;
}

.site-head small {
	display: block;
}

.site {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
  align-items: center;
	flex-direction: column;
	margin: 0 auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}


.sketch {
	position: relative;
	height: 400px;
	min-width: 400px;
	margin: 0;
	overflow: visible;
	order: 2;
	
}

.bee-sketch {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.red {
	background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-1.png) no-repeat center center;
	opacity: 1;
	animation: red 3s linear infinite, opacityRed 5s linear alternate infinite;
}

.blue {
	background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-1.png) no-repeat center center;
	opacity: 0;
	animation: blue 3s linear infinite, opacityBlue 5s linear alternate infinite;
}

.site-button{
	&:hover{
		background-color: #1366d6;
		box-shadow: rgba(0, 0, 0, .05) 0 5px 30px, rgba(0, 0, 0, .05) 0 1px 4px;
		opacity: 1;
		transform: translateY(0);
		transition-duration: .35s;
	}
	&:active{
		box-shadow: rgba(0, 0, 0, .1) 0 3px 6px 0, rgba(0, 0, 0, .1) 0 0 10px 0, rgba(0, 0, 0, .1) 0 1px 4px -1px;
		transform: translateY(2px);
		transition-duration: .35s;
	}
	&::before{
		display: inline-block;
		margin-right: 7px;
		width: 18px;
		height: 18px;
		background-image: url("../assets/img/backicon.svg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		content: "";
	}
	appearance: none;
	display: flex;
	align-items: center;
    backface-visibility: hidden;
    background-color: #2f80ed;
    border-radius: 10px;
    border-style: none;
    box-shadow: none;
    box-sizing: border-box;
	text-transform: uppercase;
    color: #fff;
    font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
    font-size: 15px;
    font-weight: 500;
    height: 50px;
    letter-spacing: normal;
    line-height: 1.5;
    outline: none;
    overflow: hidden;
    padding: 14px 30px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
	margin: 0 auto;
}

@media only screen and (min-width: 780px) {
  .site {
		flex-direction: row;
		padding: 1em 3em 1em 0em;
	}
	
	.site-head {
		text-align: right;
		order: 2;
		padding-bottom: 2em;
		padding-left: 2em;

	}
	
	.sketch {
		order: 1;
	}
}


@keyframes blue {
	0% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-1.png) 
  }
	9.09% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-2.png) 
  }
	27.27% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-3.png) 
  }
	36.36% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-4.png) 
  }
	45.45% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-5.png) 
  }
	54.54% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-6.png) 
  }
	63.63% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-7.png) 
  }
	72.72% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-8.png) 
  }
	81.81% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-9.png) 
  }
	100% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/blue-1.png) 
  }
}

@keyframes red {
	0% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-1.png) 
  }
	9.09% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-2.png) 
  }
	27.27% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-3.png) 
  }
	36.36% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-4.png) 
  }
	45.45% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-5.png) 
  }
	54.54% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-6.png) 
  }
	63.63% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-7.png) 
  }
	72.72% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-8.png) 
  }
	81.81% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-9.png) 
  }
	100% {
		background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/198554/red-1.png) 
  }
}

@keyframes opacityBlue {
	from {
		opacity: 0
	}
	25% {
		opacity: 0
	}
	75% {
		opacity: 1
	}
	to {
		opacity: 1
	}
}

@keyframes opacityRed {
	from {
		opacity: 1
	}
	25% {
		opacity: 1
	}
	75% {
		opacity: .3
	}
	to {
		opacity: .3
	}
}