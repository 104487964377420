.page-status{
    &--1{
        &::before{
            display:inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 16px;
            height: 16px;
            background-image: url("../../../../../../assets/icon/time.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content:"";
        }
    }
    &--2{
        &::before{
            display:inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 16px;
            height: 16px;
            background-image: url("../../../../../../assets/icon/Yubor.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content:"";
        }
    }
    &--3{
        &::before{
            display:inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 27px;
            height: 10px;
            background-image: url("../../../../../../assets/icon/ptichka.svg");
            background-position: center; 
            background-repeat: no-repeat;
            background-size: cover;
            content:"";
        }
    }
}