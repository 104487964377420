.status_info {
    background: #FFFFFF;
    width: 100%;
    // display: flex;
    // justify-content: center;
    border: 1px solid #E1E1E1;
    border-radius: 40px;
    padding: 8px;

    .status_info_title {
        margin: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #888888;
        text-align: center;
    }
}

.rol_ariza_bottom_top2 {
    margin-bottom: 20px;
}

.rol_ariza_bottom_bigbox_info_1 {
    display: flex;
    justify-content: space-between;

    .rol_ariza_bottom_block1 {
        width: 43% !important;
    }
}

.rol_ariza_bottom_block1 {
    border: 1px solid #E1E1E1;
    display: flex;

    p {
        margin: 0;
        padding: 8px;
        width: 45%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #111111;

        &:nth-child(2) {
            border-left: 1px solid #E1E1E1;
        }
    }
}

.rol_ariza_bottom_div_t6 {
    display: flex;
    justify-content: space-between;

    .rol_ariza_bottom_div_inner_block_select_inner1 {
        width: 50%;
        border: 1px solid #E1E1E1;

        // padding: 8px;
        p {
            margin: 0;
            padding: 15px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            color: #111111;

            &:nth-child(2) {
                border-top: 1px solid #E1E1E1;
            }
        }
    }
}

.single_table_all_block_bottom {
    height: 250px;
    overflow-y: scroll;
}

.single_table_all_title {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4D4D4D;
}

.single_table_all_block {
    background: #FFFFFF;
    padding: 20px;
    border: 1px solid #E1E1E1;
    border-radius: 12px;
}

.single_table_all_block_top {
    display: flex;
    align-items: center;
    border-bottom: solid #E1E1E1;

    button {
        background: none;
        padding: 20px 60px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #4D4D4D;

        &:hover {
            color: #1464C0;
        }

    }
}

.single_table_document {
    display: flex;
    justify-content: space-between;

    .t9 {
        width: 48%;
    }
}

.document_left_title_block {
    height: 200px;
    overflow-y: scroll;

    textarea {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #4D4D4D;
        margin: 0;
        width: 100%;
        height: 150px !important; 
    }
}

.rol_ariza_top {
    margin-bottom: 20px;
}
.div-1{
    padding-top: 10px;
    text-align: center;
    padding-bottom: 10px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #9AC4F4;
    color: #1464C0;
    &::before{
        display: inline-block;
        vertical-align: middle;
        margin-right: 11px;
        width: 14px;
        height: 16px;
        background-image: url("../../assets/icon/ariza.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        content: "";
    }
    &--1{
        padding-top: 10px;
    text-align: center;
    padding-bottom: 10px;
    border-radius: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #9AC4F4;
    color: #1464C0;
    &::before{
        display: inline-block;
        vertical-align: middle;
        margin-right: 11px;
        width: 14px;
        height: 16px;
        background-image: url("../../assets/icon/calendar.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        content: "";
    }
    }
}