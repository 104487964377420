.inspections-container {
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}
.inspections-title {
  position: sticky;
  top: 0;
  padding: 0 0 20px 0;
  background-color: #eff2f5;
}
.inspections-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.close-button {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: rgba(66, 65, 65, 0.21);
}
.inspection-box {
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 12px;
  border: 1px;
  background-color: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &-view-icon {
      width: 40px;
      height: 40px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-title {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  &-table {
    &-col {
      border: 1px solid rgba(66, 65, 65, 0.14);
      gap: 8px;
      &:last-child {
        border-top: 0;
      }
      div {
        border-right: 1px solid rgba(66, 65, 65, 0.14);
        padding: 10px 16px 10px 16px;
      }
    }
  }
}
