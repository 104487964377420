.text {
  &-capitalize {
    text-transform: capitalize;
  }
  &-lowercase {
    text-transform: lowercase;
  }
  &-uppercase {
    text-transform: uppercase;
  }
}

.flex {
  display: flex;
  &-col {
    flex-direction: column;
    &-reverse {
      flex-direction: column-reverse;
    }
  }
}

.items {
  &-center {
    align-items: center;
  }
}

.justify {
  &-center {
    justify-content: center;
  }
}

@for $i from 0 through 100 {
  .p-#{$i} {
    padding: #{$i}px;
  }
  .\!p-#{$i} {
    padding: #{$i}px !important;
  }

  .m-#{$i} {
    margin: #{$i}px;
  }
  .px-#{$i} {
    padding-left: #{$i}px;
    padding-right: #{$i}px;
  }
  .mx-#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }
  .py-#{$i} {
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }
  .my-#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }
}

// [class^="!"] {
//   color: orange !important;
// }
