.sklad_top_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.sklad_top_block_inner {
  display: flex;
  align-items: center;

  button {
    margin-left: 10px;

    &:nth-child(2) {
      background: #f69641 !important;
    }

    &:nth-child(4) {
      background: #18cf6c !important;
    }

    &:nth-child(1) {
      background: white !important;
      color: #4d4d4d;
    }
  }

  .sklad_title {
    margin-right: 10px;
  }
}

.partiya_number {
  margin-top: 20px;
  width: 100%;
  padding-bottom: 20px;

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }
}

.one_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block_two {
  div {
    width: 100%;
  }

  button {
    margin-bottom: 15px;
  }
}

.pos_btn {
  button {
    position: absolute;
    right: 0 !important;
  }
}

// label[data-shrink="true"] {
//     top: -7px !important;
// }

// .MuiFormControl-root label {
//     .css-u9osun.Mui-focused{
//         top:-7px;
//     }
// }
