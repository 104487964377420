.css-1qqsdnr-MuiAutocomplete-root {
  // width: 300px !important;
  flex-shrink: 0;
}
.uzerkin {
  .sarflov_block {
    padding: 15px;
  }
  .sarflov_block_inner_div {
    height: auto !important;
  }
}
