.chart-head{
  margin-top: 24px;
  margin-bottom: 0;
  margin-left: 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #111111;
}
.chart-width{
  margin-top: 24px !important;
  max-width:1200px !important ;
   width: 100% !important;
   height: 100% !important;
   margin: 0 auto;
  background-color: #fff;
}


