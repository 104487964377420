.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  &__body {
    max-width: 900px;
    width: 100%;
    background-color: #eff2f5;
    max-height: 90vh;
    position: relative;
    padding: 20px;
    border-radius: 16px;
    overflow-y: auto;
  }
  &__close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #f3f3f3;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: 24px;
  }
}

.form__fields {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.textarea {
  max-width: 100%;
  width: 100%;
  background-color: transparent;
  outline: none;
  border-radius: 4px;
  min-height: 120px;
  padding: 10px;
  border-color: #c3c4c5;
}
.no-data {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
