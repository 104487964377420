.marizalar {
    .ariza_top {
        padding: 0 20px;
    }

    .ariza_bottom {
        padding: 0 20px;
    }

    .ariza_bottom_top {
        margin-top: 20px;
    }
    //  .css-1ygcj2i-MuiTableCell-root {
    //      border: 0px solid transparent !important;
    // }

    // .css-1ex1afd-MuiTableCell-root {
    //     border: 0px solid transparent !important;
    // }

    .ariza_bottom_top {
        border: 0px solid transparent !important;

        .ariza_link {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #4D4D4D;

            &.active {
                color: #1464C0;
            }
        }

    }

    // .css-1yhpg23-MuiTableCell-root {
    //     border: 0px solid transparent !important;
    // }
}

.filter_region {
    width: 20%;
    padding-left: 20px;

    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        height: 40px;
    }

    .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        top: -6px;
    }
}

.status_btn {
    border: 1px solid #9AC4F4;
    background: none;
    padding: 8px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    border-radius: 24px;
    color: #1464C0;
    &::before{
        display: inline-block;
        margin-right: 11px;
        width: 14px;
        height: 14px;
        background-image: url("../../../assets/icon/new.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
    }
    &--not{
    padding: 8px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    background: #fff;
    border: 1px solid #E1E1E1;
    border-radius: 24px;
    color: #888888;
    &::before{
        display: inline-block;
        margin-right: 11px;
        width: 19px;
        height: 8px;
        background-image: url("../../../assets/icon/p1.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
    }
    }
    &--not2{
        padding: 8px 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        background: #fff;
        border: 1px solid #d12727;
        border-radius: 24px;
        color: #c02020;
        &::before{
            display: inline-block;
            margin-right: 11px;
            width: 19px;
            height: 8px;
            background-image: url("../../../assets/icon/cancel.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content: "";
        }
        }
}