.ariza_bottom_top {
  button {
    color: #4d4d4d;
    &.active {
      color: #1464c0;
    }
  }
}
.css-heg063-MuiTabs-flexContainer {
  align-items: center !important;
}
