.chiqim_card_left {
  width: 20%;

  button {
    // background: orange !important;
    color: orange !important;
  }
}

.chiqim_card {
  display: flex;
  justify-content: space-between;
  min-height: 180px;
  .chiqim_card_center {
    width: 75%;
    padding-right: 20px;
    .bottom {
      display: flex;
      align-items: center;
      margin: 20px 0;
      div {
        padding: 8px 16px;
        background: #ffffff;
        display: block;
        border: 1px solid #fcd8b8;
        border-radius: 40px;
        margin-right: 15px;
        min-width: 80px;
      }

      span {
        margin: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #f69641;
        width: 120px;
      }
    }
  }
}

.kirim_card_center_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  .top_left {
    display: flex;
    align-items: center;
    width: 40%;

    p {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #888888;
    }

    h5 {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #4d4d4d;
      margin: 0;
      margin-left: 10px;
    }
  }

  .top_right {
    display: flex;
    width: 58%;
    justify-content: flex-end;
    padding: 20px 0 0 0;

    .kirim_card_right_left {
      margin-left: 20px;

      p {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        text-align: right;
        color: #4d4d4d;
      }

      span {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 15px;
        text-align: right;
        color: #888888;
        display: block;
      }
    }
  }
}
