.sarflov {
  padding: 0 20px;

  .sarflov_inner {
    display: flex;
    align-items: center;

    .sarflov_title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #111111;
      margin-left: 40px;
    }
  }

  .css-sghohy-MuiButtonBase-root-MuiButton-root {
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background-color: #ddebfb;
    color: #1464c0;

    &:hover {
      color: #ffffff;
    }
  }
}

.sent_btn {
  margin-top: 30px;
}
.sarflov_block_inner_div_title {
  margin: 0;
  margin-bottom: 20px;
}

.sarflov_block_inner_two {
  margin-bottom: 10px;
  // border-bottom: 1px solid #E1E1E1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 25px;
  width: 100%;

  .num_title {
    margin: 0;
    margin-top: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #111111;
  }

  .close_arr_btn {
    margin-top: 50px;
  }

  .sarflov_block_inner_div_two {
    &:first-child {
      width: 5%;
    }

    &:nth-child(2) {
      width: 23%;
    }

    &:nth-child(3) {
      width: 10%;
    }

    &:nth-child(4) {
      width: 10%;
    }

    &:nth-child(5) {
      width: 23%;
    }

    &:nth-child(6) {
      width: 23%;
    }

    &:nth-child(7) {
      width: 5%;
    }
  }
}

.add_btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.sarflov_block_inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 25px;
  width: 100%;

  .sarflov_block_inner_div_title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #111111;
  }

  .sarflov_block_inner_div {
    width: 23%;

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      width: 100%;
    }
  }
}

.sarflov_block {
  background: #ffffff;
  border-radius: 12px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  overflow-y: hidden;
}
#parent {
  height: 400px;
  overflow: auto;
}
#child {
  height: 330px;
}

.sarflov_block_comment {
  background: #ffffff;
  border-radius: 12px;
  padding: 25px;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 48%;
  height: 310px;

  textarea {
    width: 100%;
    height: 200px !important;
    padding: 10px;
    border: 2px solid #e1e1e1;
  }
}

.sarflov_block_inner_div1 {
  margin-top: 40px;
}

.sarflov_block_inner_div {
  height: 200px;
  overflow-y: scroll;
}
.sarflov_block_title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1464c0;
  margin: 0;
  width: max-content;
}

.sarflov_comment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input_download {
  display: none;
}

.input_tyle_download {
  background: #f4f4f4;
  border-radius: 4px;
  width: 100%;
  padding: 10px 40px;
  // margin-top: 35px;
  margin: 10px 0;
  display: inline-table;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #4d4d4d;
  &:hover {
    color: #1464c0;
    cursor: pointer;
  }

  .label_img {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.sarflov_block_comment_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px 8px 40px;
    background: #ddebfb;
    border-radius: 12px;
    cursor: pointer;
    color: #1464c0;
    position: relative;

    .scrip_file {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      margin: auto;
    }
  }
}

.close_file {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #4d4d4d;
}
.sarflov-link {
  margin-right: 16px;
  padding: 8px 43px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1464c0;
  background-color: #ddebfb;
  border-radius: 12px;
}
.site-footer {
  padding-top: 16px;
  padding-bottom: 8px;
  background-color: #ffffff;
  border-top: 1px solid #e7ebf2;
}
