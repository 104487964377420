.css-vtdehq-MuiTableCell-root{
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
}
.css-dsuxgy-MuiTableCell-root{
    width: inherit !important;
}

.msklad_back {
     margin-top: 28px;
      margin-top: 30px;
    .css-sghohy-MuiButtonBase-root-MuiButton-root:first-child {
    background-color: #DDEBFB;
    color: #1464C0;
}
}