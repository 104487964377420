.n-tabs {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;

  &-item {
    min-width: 200px;
    padding: 20px 30px;
    border: 1px solid rgba($color: #000, $alpha: 0.3);
    text-align: center;
    cursor: pointer;
    &.active {
      background-color: blue;
      color: white;
    }
  }
}
