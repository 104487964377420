.chiqim-page{
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border: 1px solid #E1E1E1;
    border-radius: 12px;
}
.chiqim-link{
    margin-right: 20px;
    padding: 8px 16px;
    background-color: #F4F4F4;
    border-radius: 12px;
    &::before{
        display: inline-block;
        margin-right: 5px;
        width: 19px;
        height: 13px;
        background-image: url("../../../../../assets/icon/g1.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
    }  
}