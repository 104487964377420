@import "./assets/scss/container.scss";
@import "./assets/scss/normailze.scss";
html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Nunito", sans-serif;
  background: #e7ebf2;
}
button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  // border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.app {
  padding-bottom: 50px;
}

a {
  text-decoration: none;
  color: black;
  transition: all 0.5s ease;
  &:hover {
    color: rgb(19, 91, 174);
  }
}

.app_right {
  width: 100%;
  padding-left: 60px;
}

.app {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.none {
  display: none;
}

button {
  cursor: pointer !important;
}
tbody {
  tr:nth-of-type(2n) {
    background-color: #e7ebf1;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 1440px) {
  .n-tab-header {
    &-item {
      font-size: 12px !important;
    }
  }
}
