@import "../../../../assets/scss/mixin.scss";
.kirim_card {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}
.kirim_card_left {
  border-right: 1px solid #e1e1e1;
  width: 20%;
  padding: 20px 0 20px 20px;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #4d4d4d;
    margin: 0;
  }
  button {
    display: flex;
    justify-content: center;
    margin: auto;
    font-size: 20px;
  }
}

.kirim_card_center {
  width: 40%;
  padding: 20px 0;
  display: flex;
  justify-content: flex-start;
  div {
    padding: 8px 16px;
    background: #ffffff;
    display: block;
    border: 1px solid #9ac4f4;
    border-radius: 40px;
    height: 42px;
    margin-right: 15px;
    overflow: auto;
  }
  span {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #1464c0;
  }
}
.kirim_card_right {
  width: 30%;
  padding: 20px 20px 20px 0;
  display: flex;

  .kirim_card_right_left {
    // margin-left: 20px;
    p {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: right;
      color: #4d4d4d;
    }
    span {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      color: #888888;
      margin: 0;
      margin-top: 15px;
    }
  }
}
.vosita {
  padding: 0.4em;
  div {
    display: flex !important;
  }
  span {
    color: #1464c0;
    margin-bottom: 0.5em;
  }
  &.chiqim {
    span {
      color: #18cf6c !important;
    }
  }
}
@include media(1383px) {
  .kirim_card_right {
    width: 27%;
  }
  .kirim_card_center {
    width: 52%;
  }
}
