.poliklinika {
  // height: 500px;
  .single_info {
    margin: auto;
    width: 40px;
    display: block;

    .delete_icon {
      width: 100%;
    }
  }
}

.arix_blocks {
  .css-1q1u3t4-MuiTableRow-root:nth-child(odd) {
    // background-color: #E7EBF2;
    // background-color: #E7EBF2;
  }
}

.table_head_block {
  .css-1q1u3t4-MuiTableRow-root {
    // background-color: #E7EBF2;
    background-color: white !important;
  }
}

.poliklinika_blocks {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.poliklinika_blocks_left {
  display: flex;
  align-items: center;
  h1 {
    margin: 0;
    margin-top: 28px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-left: 30px;
    color: #111111;
  }
}

.muassasa_directors {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-top: 30px;
  color: #4d4d4d;
}
.button_block {
  margin-right: 77px;
  max-width: inherit !important;
  display: flex;
  justify-content: space-between !important;
}

.arix_blocks {
  padding: 0 20px;
}

.input_blocks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  }
}

.input_blocks1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    // width: 45% !important;
    &:nth-child(2) {
      width: 30% !important;
    }
    &:nth-child(3) {
      width: 70% !important;
    }
  }
}

.pasport_block {
  display: flex;
  justify-content: space-between;
  width: 45%;
}

.select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  div {
    .css-1nrlq1o-MuiFormControl-root {
      width: 100% !important;
      margin-top: 20px;
    }
  }
}

.select_div {
  width: 45%;

  .css-i44wyl {
    width: 100% !important;
  }

  .css-13sljp9 {
    width: 100% !important;
  }
}

.select_div-11 {
  width: 45% !important;
  div {
    width: 100% !important;
  }
}

.button_block1 {
  margin-top: 15px;
}

.orqaga {
  margin-bottom: 150px;
  a {
    display: inline-block;
    color: blue;
  }
}

.form_control_scrool {
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.css-1azl6jz-MuiTableCell-root {
  font-weight: bold !important;
}

.css-1ygcj2i-MuiTableCell-root {
  // font-weight: bold !important;
}
.dializ_input {
  width: 100%;
  margin: 20px 0;

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  }

  .css-i44wyl {
    width: 100% !important;
  }
}

.checkbox_type {
  display: flex;
  align-items: center;
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .checkClass {
    margin-left: 6px;
  }
}

.kasalliklar {
  // display: none;
  width: 45%;
}

.kasalliklar1 {
  display: none;
}

.popup {
  width: 30px;
  height: 30px;
  display: block;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: relative;
  &:hover {
    .popup_inner {
      display: block;
    }
  }
}
.popup_inner {
  overflow-y: scroll;
  width: 500px;
  position: fixed;
  margin: auto;
  height: 600px;
  background: rgb(176, 175, 175);
  left: 25%;
  top: 25%;
  z-index: 111;
  display: none;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  p {
    color: white;
    font-size: 18px;
    text-align: left;
    margin: 0;
    border-bottom: 1px solid rgb(143, 143, 143);
    padding-bottom: 5px;
  }

  h6 {
    color: white;
    font-size: 20px;
    text-align: left;
    font-weight: bold;
    margin: 0;
  }
}

.select_div_100 {
  .css-1nrlq1o-MuiFormControl-root {
    width: 100% !important;
  }

  .filed label {
    top: -16px;
  }
}
.aligin {
  text-align: center;
}

.button_modal {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
}

.serach_person {
  width: 300px;
  div {
    width: 100% !important;
  }
}

.delete_div {
  width: 50px;
  height: 40px;
  cursor: pointer;
  background: none;
  border: none;
}
.delete_icon {
  // width: 100%;
  // height: 100%;
}

.delete_btn_group {
  display: flex;
  justify-content: space-around;
}

.delete_btn_group {
  .red_btn {
    margin-bottom: 14px;
    background-color: red !important;
  }
}

.seanslar_btn {
  border: none;
  background: none;
  padding: 5px 10px;
  background: #1976d2;
  border-radius: 6px;
  color: white;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.voice-btn {
  width: 30px;
  height: 30px;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  top: -6px !important;
}
.input-sel label {
  top: -37px;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:nth-child(even) {
  background-color: #fff;
}
