.moh-table-container {
  padding: 0 0 10px;
}

.total-row {
  margin-top: 30px;
}

.action-btn {
  display: flex;
  gap: 10px;
  img {
    cursor: pointer;
  }
}

.close-icon {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
  // position: absolute;
  // right: 0;
  // top: 0;
}
.doctor-examination,
.patient-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1464c0;
    margin: 0;
    margin-bottom: 20px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  &-box {
    padding: 28px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 12px;
    width: 100%;
    &-header {
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        padding: 6px 10px;
        border-radius: 6px;
        color: #fff;
        background-color: #1464c0;
        cursor: pointer;
      }
    }
    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: #1464c0;
      margin: 0;
    }
    &-fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      border-bottom: 1px solid;
      &.v-list {
        grid-template-columns: repeat(3, 1fr);
      }
      &:first-child {
        border-top: 1px solid;
      }

      &-field {
        padding: 10px;
        &:first-child {
          border-right: 1px solid;
          border-left: 1px solid;
        }
        &.v-list {
          &:nth-child(2) {
            border-right: 1px solid;
          }
        }
        &:last-child {
          border-right: 1px solid;
        }
      }
    }
  }
}
