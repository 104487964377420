.monitor__section{
    width: 100%;
    
}
.header__monitor{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 28px 0;
    .oshp__title{
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #111111;
    }
}

.button{
    display: flex;
    gap: 20px;
    .addButton{
        background: #1464C0;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 4px;
        color: white;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .downoaldBtn{
        background: #18CF6C;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 4px;
        color: white; 
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
}
.section__monitor{
    width: 100%;
    height: 856px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #FFFFFF;
    border-radius: 12px;
    .title__nothing{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .addButton{
            background: #1464C0;
            border-radius: 12px;
            padding: 8px 16px;
            gap: 4px;
            color: white;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            width: 165px;
            height: 40px;
        }
        p{
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            color: #4D4D4D;
        }
    }
}
.none{
    display: none;
}

.form{
    h2{
        margin: 28px;
    }
    .form__section{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 28px;
    }
    .name{
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #1464C0;
    }
    .inputs{
        display: flex;
        height: 140px;
        align-items: center;
        margin-top: -20px;
        border-bottom: 1px solid #E1E1E1;
        gap: 16px;
        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #111111;
        }
        
            .input_a{
                
            }
            i{
               margin-top: 40px;
                cursor: pointer;
            }
            .MuiSvgIcon-root{
                color: #4D4D4D;
                
            }
           
    }
    .btnad{
        width: 100%;
        height: 34px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        background: #DDEBFB;
        border-radius: 12px;
        color: #1464C0;
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor:  pointer;
    }
    .addtizm{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 4px;
        
        width: 488px;
        height: 40px;
        
        /* 500-Primary */
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */


        /* White */

        color: #FFFFFF;
        background: #1464C0;
        border-radius: 12px; 
    }
    .buttontizim{
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 50px ;
    }
}
.back{
    width: 99px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1464C0;
    margin-top: 20px;
    i{
        margin-top: 5px;
    }
}
.header{
    display: flex;
}
.site-add{
    padding:346px 577px ;
    background: #fff;
    border-radius: 12px;
}
.site-add__page{
    width: 413px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    &::before{
        display: block;
        text-align: center;
        width: 413px;
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        content: attr(data-content);
    }
}
